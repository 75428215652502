import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/Spray";
import { history } from "../../store";
import { find, get, map } from "lodash";
import { actionType as sprayDiaryActionType } from "constants/SprayDiary";
import uuid from "uuid/v4";
import { pageChecker } from "actions/HourlyPayroll/hourlyPayroll";
let uniqId;

const parseResponseValue = ({ content, ...restData }, allAreasFromStorage) => ({
  content: content.map(({ areas, ...rest }) => ({
    areas: areas.map(({ areaId, ...restOfArea }) => ({
      area: find(allAreasFromStorage, a => a.id === areaId),
      ...restOfArea
    })),
    ...rest
  })),
  ...restData
});

export const getBatchNumbersLastYear = chemicals => async dispatch => {
  const url = "/purchase_orders/batch_numbers_last_year";

  try {
    await dispatch({
      type: actionType.GET_BATCH_NUMBERS_START
    });
    const res = await Promise.all(
      chemicals.map(async checmical => {
        const { data } = await axiosProxy({
          params: {
            chemicalId: checmical.id
          },
          method: "GET",
          url
        });
        return {
          id: checmical.id,
          data
        };
      })
    );
    await dispatch({
      type: actionType.GET_BATCH_NUMBERS,
      payload: res
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_BATCH_NUMBERS_FAIL,
      payload: error
    });
  }
};

export const getBatchNumbers = () => async dispatch => {
  const url = "/purchase_orders/batch_numbers";

  try {
    dispatch({
      type: actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS_START
    });

    const res = await axiosProxy({
      method: "GET",
      url
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS_FAIL,
      payload: error
    });
  }
};

export const getSprayTasksList = ({
  pagination,
  search = "",
  sort,
  loadMore = false
}) => async (dispatch, getState) => {
  let url = "/spray_tasks_v2";
  const {
    user: { user },
    areas
  } = getState();
  const params = new URLSearchParams();
  if (pagination) {
    params.append("page", pagination.page);
    params.append("size", pagination.size);
  } else {
    params.append("unpaged", "true");
  }
  const sortToSend = sort && sort.length ? sort : ["id,desc"];
  sortToSend.forEach(field => {
    params.append("sort", field);
  });

  //TODO: get search as array from the first place
  search &&
    search.split("&").forEach(field => {
      const getField = field && field.split("=");
      if (getField[1] && getField[1] !== "null") {
        params.append(getField[0], getField[1]);
      }
    });
  dispatch(getBatchNumbers());
  dispatch({
    type: actionType.GET_SPRAY_TASKS_START,
    meta: {
      callFrom: history.location.pathname,
      user,
      offline: {
        effect: {
          url,
          params,
          method: "GET",
          dataToAdd: { loadMore },
          callFrom: "getSprayTasksList",
          payloadConstructor: data =>
            parseResponseValue(data, areas.list.content)
        },
        commit: {
          type: actionType.GET_SPRAY_TASKS,
          meta: {}
        },
        rollback: {
          type: actionType.GET_SPRAY_TASKS_FAIL,
          meta: {}
        }
      }
    }
  });
};

export const clearSprayTasksListForRecords = () => async dispatch => {
  await dispatch({
    type: actionType.CLEAR_SPRAY_TASKS_LIST_FOR_RECORDS
  });
};

export const getSprayTasksListForRecords = ({ from, to }) => async (
  dispatch,
  getState
) => {
  const url = "/spray_tasks_v2";
  const { areas } = getState();

  try {
    dispatch({
      type: actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS_START
    });

    const params = new URLSearchParams();
    params.append("unpaged", true);
    params.append("dateAfter", from);
    params.append("dateBefore", to);

    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });

    await dispatch({
      type: actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS,
      payload: parseResponseValue(res.data, areas.list.content)
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_SPRAY_TASKS_LIST_FOR_RECORDS_FAIL,
      payload: error
    });
  }
};

export const getCropsList = () => async (dispatch, getState) => {
  const url = `/spray_tasks/crops?unpaged=true&sort=name,asc`;
  const {
    user: { user }
  } = getState();
  return dispatch({
    type: actionType.GET_CROPS_START,
    meta: {
      callFrom: history.location.pathname,
      user,
      offline: {
        effect: {
          url,
          method: "GET"
        },
        commit: {
          type: actionType.GET_CROPS,
          meta: {}
        },
        rollback: {
          type: actionType.GET_CROPS_FAIL,
          meta: {}
        }
      }
    }
  });
};

export const getVarietiesList = () => async (dispatch, getState) => {
  const url = `/spray_tasks/varieties?unpaged=true&sort=name,asc`;
  const {
    user: { user }
  } = getState();
  return dispatch({
    type: actionType.GET_VARIETIES_START,
    meta: {
      callFrom: history.location.pathname,
      user,
      offline: {
        effect: {
          url,
          method: "GET"
        },
        commit: {
          type: actionType.GET_VARIETIES,
          meta: {}
        },
        rollback: {
          type: actionType.GET_VARIETIES_FAIL,
          meta: {}
        }
      }
    }
  });
};

export const getTasksIdsList = () => async (dispatch, getState) => {
  const url = `/spray_tasks/ids?unpaged=true&sort=id,asc`;
  const {
    user: { user }
  } = getState();
  return dispatch({
    type: actionType.GET_TASKS_IDS_START,
    meta: {
      callFrom: history.location.pathname,
      user,
      offline: {
        // the network action to execute:
        effect: {
          url,
          method: "GET"
        },
        commit: {
          type: actionType.GET_TASKS_IDS,
          meta: {}
        },
        rollback: {
          type: actionType.GET_TASKS_IDS_FAIL,
          meta: {}
        }
      }
    }
  });
};

export const setTaskBlockDone = ({
  taskId,
  blockId,
  done,
  blockType
}) => async dispatch => {
  const url = `/spray_tasks/${taskId}/${blockType}/${blockId}/update_done`;

  try {
    dispatch({
      type: actionType.SET_TASK_BLOCK_DONE_START
    });
    const { res } = await axiosProxy({
      method: "POST",
      data: {
        actualTanks: 0,
        done
      },
      url
    });

    dispatch({
      type: actionType.SET_TASK_BLOCK_DONE,
      payload: {
        data: res.data,
        taskId,
        blockId,
        blockType,
        done
      }
    });
  } catch (error) {
    dispatch({
      type: actionType.SET_TASK_BLOCK_DONE_FAIL,
      payload: error
    });
  }
};

export const deleteSprayTasks = ({ id, _id }) => async (dispatch, getState) => {
  const url = `/spray_tasks/${id}`;

  const {
    offline: { online },
    user: { user }
  } = getState();

  return dispatch({
    type: actionType.DELETE_SPRAY_TASK_START,
    payload: { id, _id },
    meta: {
      reqId: _id,
      online,
      user,
      offline: {
        effect: {
          url,
          method: "DELETE",
          skipResponse: true
        },
        commit: {
          type: actionType.DELETE_SPRAY_TASK,
          meta: { id, _id }
        },
        rollback: {
          type: actionType.DELETE_SPRAY_TASK_FAIL,
          meta: { id, _id }
        }
      }
    }
  });
};

export const updateTaskItem = ({ id, data, _id }) => (dispatch, getState) => {
  const { sprayDiary, spray } = getState();
  const { taskNames } = sprayDiary;
  const content = get(spray, "tasks.data.content");
  const editedTaskPrevState = find(content, task => task.id === id);

  if (
    editedTaskPrevState &&
    editedTaskPrevState.taskName &&
    editedTaskPrevState.taskName !== get(data, "taskName") &&
    taskNames.content
  ) {
    const newTaskNames = map(taskNames.content, taskName =>
      taskName === editedTaskPrevState.taskName ? data.taskName : taskName
    );

    dispatch({
      type: sprayDiaryActionType.GET_TASK_NAME_LIST_SUCCESS,
      payload: { content: newTaskNames }
    });
  }
  return dispatch({
    type: actionType.UPDATE_TASK_ITEM,
    payload: { id, data, _id }
  });
};

export const setFieldValue = ({ fieldName, fieldValue }) => {
  return {
    type: actionType.SET_PROPERTY_VALUE,
    payload: { fieldName, fieldValue }
  };
};

export const setUrlString = urlString => dispatch => {
  return dispatch({
    type: actionType.SET_URL_STRING,
    payload: urlString
  });
};

export const clearError = () => dispatch => {
  return dispatch({
    type: actionType.CLEAR_ERROR
  });
};

export const clearTasksError = () => dispatch => {
  return dispatch({
    type: actionType.CLEAR_TASKS_ERROR
  });
};

export const fetchSprayTasks = ({
  filters = {},
  sort,
  pagination,
  unpaged = false
}) => async (dispatch, getState) => {
  const { areas, employee, chemical } = getState();
  const url = "/spray_tasks_v3";
  try {
    dispatch(setActiveFilter(filters));
    dispatch({ type: actionType.GET_SPRAY_TASKS_START });
    const params = new URLSearchParams();
    params.append("page", pagination.page);
    params.append("size", pagination.size);

    const sortToSend = sort && sort.length ? sort : ["date,desc"];
    sortToSend.forEach(field => {
      params.append("sort", field);
    });

    const filtersToSend = Object.entries(filters).reduce(
      (acc, [name, value]) => {
        if (name === "date" && (!!value[0] || !!value[1])) {
          acc.push(["dateAfter", value[0]]);
          value[1] && acc.push(["dateBefore", value[1]]);
        } else if (name === "plannedDate" && (!!value[0] || !!value[1])) {
          acc.push(["plannedDateAfter", value[0]]);
          value[1] && acc.push(["plannedDateBefore", value[1]]);
        } else if (name === "workingTime" && (!!value[0] || !!value[1])) {
          acc.push(["workingTimeAfter", value[0]]);
          value[1] && acc.push(["workingTimeBefore", value[1]]);
        } else if (name === "endDate" && (!!value[0] || !!value[1])) {
          acc.push(["endDateAfter", value[0]]);
          value[1] && acc.push(["endDateBefore", value[1]]);
        } else if (
          name === "season" &&
          !filters?.date?.length &&
          !!(value[0] && !!value[1])
        ) {
          acc.push(["dateAfter", value[0]]);
          acc.push(["dateBefore", value[1]]);
        } else if (
          name !== "season" &&
          name !== "date" &&
          name !== "plannedDate"
        ) {
          if (Array.isArray(value)) {
            value.forEach(val => acc.push([name, val]));
          } else {
            acc.push([name, value]);
          }
        }
        return acc;
      },
      []
    );

    filtersToSend.forEach(([name, value]) => {
      params.append(name, value);
    });
    uniqId = uuid();
    const res = await axiosProxy({ method: "GET", uniqId, params, url });

    const newData = {
      ...res.data,
      content: res.data.content.map((item, index) => ({
        ...item,
        assignees: item.assignees.map(a => ({
          ...a,
          employee: employee.list.content.find(e => e.id === a.employeeId)
        })),
        supervisors: item.supervisors.map(s => ({
          ...s,
          employee: employee.list.content.find(e => e.id === s.employeeId)
        })),
        chemicals: item.chemicals.map(chem => ({
          ...chem,
          chemical: chemical.list.content?.find(c => c.id === chem.chemicalId),
          chemicalRate: chemical.list.content
            ?.find(c => c.id === chem.chemicalId)
            ?.rates?.find(r => r.id === chem.chemicalRateId)
        })),
        creator: employee.list.content.find(e => e.id === item.creatorId)
      }))
    };

    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.GET_SPRAY_TASKS,
        payload: parseResponseValue(newData, areas.list.content)
      });
      dispatch(pageChecker(true));
    }
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_SPRAY_TASKS_FAIL,
      payload: error
    });
  }
};

export const setActiveFilter = filter => dispatch => {
  dispatch({
    type: actionType.SET_ACTIVE_FILTERS,
    payload: filter
  });
};

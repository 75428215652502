import PropTypes from "prop-types";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback
} from "react";
import styles from "../../Farms/Farms.module.css";
import style from "./GeneralTasksList.module.css";
import { Button, Grid, Header, Input, Sidebar } from "semantic-ui-react";
import Layout from "../../../components/Layout";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchGeneralTasks,
  fetchSubTaskList,
  setLocationToState
} from "../../../actions/GeneralTasks/generalTasks";
import GeneralTasksTable from "./components/GeneralTasksTable";
import Loader from "../../../components/Loader";
import Blank from "../../Blank";
import { abilitiesSelector } from "../../../selectors/user";
import { activeTaskSelector } from "../../../selectors/generalTasks";
import Pagination from "../../../components/ListTable/Pagination";
import SortDropdown from "../../../components/SortDropdown";
import { hashToObject } from "../../../utils/hashToObject";
import GeneralTaskFilter from "../../../components/Filters/GeneralTaskFilter";
import filtersOptions from "./filtersOptions";
import { getFiltersFromLocations } from "../../../utils/routeHelpers";
import { sanitizeObject } from "../../../utils/objectUtils";
import SidebarHolder from "../../../components/SidebarHolder";
import GeneralTasksSidebar from "./components/GeneralTasksSidebar";
import { debounce } from "lodash";

const sortOptions = [
  {
    key: "compositeEnd",
    text: `Sort by Date`,
    value: "compositeEnd",
    content: "Date",
    sortfields: ["compositeEnd"]
  },
  {
    key: "id",
    text: `Sort by ID`,
    value: "id",
    content: "ID",
    sortfields: ["id"]
  },
  {
    key: "name",
    text: `Sort by Name`,
    value: "name",
    content: "Name",
    sortfields: ["name"]
  }
];

const GeneralTasksList = ({
  fromReportPage,
  data,
  route,
  location,
  stateLocation,
  Can,
  generalTasksData,
  activeTask,
  isFetching,
  isWorkinTimeFetching,
  history,
  activeFiltersMatch
}) => {
  const dateRef = useRef({ dateFrom: null, dateTo: null });
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [filterClick, setFilterClick] = useState(false);
  let test = true;

  const { sort } = hashToObject(location.hash);
  const [sortValue, sortOrder] = (sort || "compositeEnd,desc").split(",");

  let { dateFrom, dateTo, filters } = useMemo(() => {
    const rawFilters = getFiltersFromLocations(
      filtersOptions,
      stateLocation &&
        !!Object.keys(stateLocation).length &&
        stateLocation != location
        ? stateLocation
        : location
    );

    const dateFrom = rawFilters.dateFrom ? rawFilters.dateFrom[0] : null;
    const dateTo = rawFilters.dateTo ? rawFilters.dateTo[0] : null;

    if (
      dateRef.current.dateFrom !== dateFrom ||
      dateRef.current.dateTo !== dateTo
    ) {
      setPageNumber(0);
      dateRef.current.dateFrom = dateFrom;
      dateRef.current.dateTo = dateTo;
    }

    const filters = {
      ids: rawFilters.ids,
      name: (rawFilters.name || []).map(v => v.replace(/\+/g, " ")),
      priority: rawFilters.priority,
      assignedEmployeeId: rawFilters.assignedEmployeeId,
      clockedInEmployeeId: rawFilters.clockedInEmployeeId,
      employeeGroupId: rawFilters.employeeGroupId,
      contractorId: rawFilters.contractorId,
      status: rawFilters.status,
      areaId: rawFilters.areaId,
      farmId: rawFilters.farmId,
      taskTypeId: rawFilters.taskTypeId,
      seasons: rawFilters.season
    };

    return { dateFrom, dateTo, filters, sort };
  }, [
    (stateLocation &&
    !!Object.keys(stateLocation)?.length &&
    stateLocation != location
      ? stateLocation
      : location
    ).hash
  ]);

  const disableChecker = !!filters.seasons
    ? "seasons"
    : !!dateFrom || !!dateTo
    ? "date"
    : null;

  const { content: tasks } = generalTasksData;
  const refetch = () => {
    return dispatch(
      fetchGeneralTasks({
        location,
        size: pageSize,
        page: pageNumber,
        dateFrom: dateFrom,
        dateTo: dateTo,
        search,
        filters: sanitizeObject(filters),
        sort: [`${sortValue},${sortOrder}`]
      })
    );
  };

  const defaultSeason = data.seasons?.reduce(function(prev, current) {
    return prev.startAt > current.startAt ? prev : current;
  }, {});

  const defaultDate = [`${defaultSeason?.startAt},${defaultSeason?.endAt}`];
  useEffect(() => {
    filters =
      defaultSeason?.startAt && defaultSeason?.endAt && !fromReportPage
        ? {
            ...filters,
            seasons: defaultDate
          }
        : {
            ...filters
          };
  }, []);

  useEffect(() => {
    !!location.hash?.length && dispatch(setLocationToState({ location }));
  }, [location]);

  useEffect(() => {
    refetch();
    test = false;
  }, []);
  const onSortChange = useCallback(
    ({ sortValue, sortOrder, sort }) => {
      setTimeout(() => {
        test &&
          dispatch(
            fetchGeneralTasks({
              location,
              size: pageSize,
              page: pageNumber,
              dateFrom: dateFrom,
              dateTo: dateTo,
              search,
              filters: sanitizeObject(filters),
              ...((sortOrder || sortOrder) && {
                sort: [`${sortValue},${sortOrder}`]
              })
            })
          );
      }, 0);
    },
    [stateLocation]
  );
  const fetchTasks = useRef(
    debounce((...params) => {
      return !isFetching && dispatch(fetchGeneralTasks(...params));
    }, 900)
  );
  useEffect(() => {
    test &&
      setTimeout(
        () =>
          fetchTasks.current({
            location,
            size: pageSize,
            page: pageNumber,
            dateFrom: dateFrom,
            dateTo: dateTo,
            search,
            filters: sanitizeObject(filters),
            sort: [`${sortValue},${sortOrder}`]
          }),
        0
      );
    setFilterClick(false);
  }, [search, pageSize, pageNumber]);
  useEffect(() => {
    filterClick &&
      setTimeout(
        () =>
          fetchTasks.current({
            location,
            size: pageSize,
            page: pageNumber,
            dateFrom: dateFrom,
            dateTo: dateTo,
            search,
            filters: sanitizeObject(filters),
            sort: [`${sortValue},${sortOrder}`]
          }),
        0
      );
    setFilterClick(false);
  }, [filters]);

  const online = useSelector(
    state => state.offline.online && state.common.online
  );

  const itemsCount = {
    itemFrom: generalTasksData.number * pageSize + 1,
    itemTo: Math.min(
      generalTasksData.number * pageSize + 1 + pageSize - 1,
      generalTasksData.totalElements
    )
  };

  const setCurrentTask = taskToSetId => {
    if (taskToSetId && (!activeTask || taskToSetId !== activeTask.id)) {
      history.replace(
        `${route.href}//${taskToSetId}/${location.hash}${activeFiltersMatch ||
          ``}`
      );
    } else if (!taskToSetId) {
      history.replace(`${route.href}${location.hash}`);
    }
  };
  const clickHendler = () => {
    dispatch(fetchSubTaskList());
  };
  const onFilterClick = boll => {
    setFilterClick(boll);
  };
  return (
    <>
      <Can not I={"access_module"} a={"general_tasks"}>
        <Blank route={route} location={location} />
      </Can>
      <Can I={"access_module"} a={"general_tasks"}>
        <Layout
          route={route}
          location={location}
          classForMain={styles.mainHolder}
        >
          <Sidebar.Pushable className={styles.farmsHolder}>
            <Sidebar
              animation="overlay"
              direction="right"
              visible={!!activeTask}
              className={styles.sidebarHolder}
              onHidden={() => setCurrentTask()}
              id="sidebarHolder"
            >
              <SidebarHolder onClose={() => setCurrentTask()}>
                <GeneralTasksSidebar task={activeTask} />
              </SidebarHolder>
            </Sidebar>
            <Sidebar.Pusher>
              <Grid divided="vertically">
                <Grid.Row columns={2} style={{ paddingBottom: 0 }}>
                  <Grid.Column>
                    <Header as="h2">{route.name}</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Link
                      onClick={() => clickHendler()}
                      className={"ui right floated button primary"}
                      to={"/tasks/create"}
                    >
                      Add task
                    </Link>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row style={{ paddingTop: 0 }}>
                  <Grid.Column mobile={16} computer={10} largeScreen={10}>
                    <Input
                      className={style.searchBar}
                      icon="search"
                      placeholder="Search"
                      value={search}
                      fluid
                      onChange={(_, { value }) => setSearch(value)}
                    />
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    computer={6}
                    largeScreen={6}
                    textAlign={"right"}
                  >
                    <SortDropdown
                      onChange={onSortChange}
                      sortOptions={sortOptions}
                      sortOrder={sortOrder}
                      sortValue={sortValue}
                      silentOnMount
                      disabled={!online}
                      defaultSort={"compositeEnd,desc"}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingTop: 0 }}>
                  <Grid.Column mobile={16} computer={16} largeScreen={13}>
                    <GeneralTaskFilter
                      onFilterClick={onFilterClick}
                      disableChecker={disableChecker}
                      options={filtersOptions}
                      stateLocation={
                        stateLocation &&
                        !!Object.keys(stateLocation).length &&
                        stateLocation != location
                          ? stateLocation
                          : location
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
                {isFetching ? (
                  <div className={style.p1em}>
                    <Loader />
                  </div>
                ) : (
                  <div style={{ width: "100%" }} className={style.p1em}>
                    {tasks.map(task => (
                      <GeneralTasksTable
                        location={
                          stateLocation &&
                          !!Object.keys(stateLocation).length &&
                          stateLocation != location
                            ? stateLocation
                            : location
                        }
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        key={`general_task_${task.id}`}
                        isWorkinTimeFetching={isWorkinTimeFetching}
                        task={task}
                        setTask={setCurrentTask}
                      />
                    ))}
                    <Pagination
                      currentPage={pageNumber}
                      totalElements={generalTasksData.totalElements}
                      pageSize={pageSize}
                      online={online}
                      itemsCount={itemsCount}
                      onPageChangeOwn={pageNumber => setPageNumber(pageNumber)}
                      pages={generalTasksData.totalPages}
                      updatePageSize={(_, data) => {
                        setPageSize(data.value);
                        setPageNumber(0);
                      }}
                      pageSizeOptions={[10, 20, 50, 100]}
                    />
                  </div>
                )}
              </Grid>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Layout>
      </Can>
    </>
  );
};

GeneralTasksList.propTypes = {
  Can: PropTypes.func,
  generalTasksData: PropTypes.shape({
    content: PropTypes.array,
    number: PropTypes.number,
    totalElements: PropTypes.number,
    totalPages: PropTypes.number
  }),
  isFetching: PropTypes.bool,
  location: PropTypes.object,
  route: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    Can: abilitiesSelector(state),
    isFetching: state.generalTasks.isFetching,
    stateLocation: state.generalTasks.location,
    fromReportPage: state.hourlyPayroll.fromReportPage,
    isWorkinTimeFetching: state.generalTasks.isWorkinTimeFetching,
    data: state.settings.data,
    generalTasksData: state.generalTasks.data,
    activeTask: activeTaskSelector(state, {
      generalTasks: state.generalTasks,
      activeTask: ownProps.match.params
        ? ownProps.match.params.activeTask
        : null
    }),
    activeFiltersMatch: ownProps.match.params.activeFilters,
    activeTaskMatch: ownProps.match.params.activeTask
  };
};

export default connect(mapStateToProps)(GeneralTasksList);

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import Layout from "../../../../components/Layout";
import styles from "./TaskCustomization.module.css";
import { fetchTaskTypeList } from "actions/GeneralTasks/generalTasks";
import { fetchSettings, createSettings } from "actions/Settings";
import { Checkbox } from "semantic-ui-react";
import Loader from "components/Loader";

function TaskCustomization({ route, location, tasksType, data, isCreating }) {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const onChangeType = id => {
    const newList = data.allowedGeneralTaskTypeIds.includes(id)
      ? data.allowedGeneralTaskTypeIds.filter(i => i !== id)
      : [...data.allowedGeneralTaskTypeIds, id];
    const dataToSend = {
      ...data,
      allowedGeneralTaskTypeIds: newList
    };
    return dispatch(createSettings(dataToSend));
  };
  useEffect(() => {
    !tasksType.length && dispatch(fetchTaskTypeList());
  }, []);
  // useEffect(() => {
  //   dispatch(fetchSettings());
  // }, []);
  return (
    <Layout route={route} location={location}>
      <div className={styles.header}>Task Customization Settings</div>
      <div className={styles.taskTypesHolder}>
        {tasksType.map(type => (
          <div key={type.id} className={styles.taskCard}>
            <img src={type.logo.presignedUrl} alt="" />
            <span className={styles.caption}>{type.name}</span>
            {isCreating ? (
              <Loader />
            ) : (
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  checked={data.allowedGeneralTaskTypeIds.includes(type.id)}
                  onChange={(e, { value }) => {
                    return setActive(!active), onChangeType(type.id);
                  }}
                  className={styles.checkbox}
                  toggle
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </Layout>
  );
}

TaskCustomization.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object
};

export default connect((state, props) => {
  return {
    tasksType: state.generalTasks.tasksType,
    data: state.settings.data,
    isCreating: state.settings.isCreating
  };
})(TaskCustomization);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Switch } from "react-router-dom";
import localStorage from "localStorage";
import persist from "@redux-offline/redux-offline/lib/defaults/persist";
import { routesPages } from "./routesPages";
import { routesConfig, userSelector, oidcSelector } from "../../selectors/user";
import {
  signInSilent,
  signIn,
  signOut,
  getRefreshToken,
  getProfilePicture,
  checkCurrentUser
} from "../../actions/User";
import { purgeAllData, setUserChecked } from "../../actions/Common";
import setSameHeight from "../../utils/setSameHeight";

import { store } from "../../index";
/*prefetch some data*/
import { getMachineryList } from "../../actions/SprayDiary/machinery";
import {
  fetchChemicalsList,
  fetchChemicalsLog
} from "../../actions/Chemicals/index";
import {
  fetchEmployeesList,
  fetchEmployeesLog,
  fetchArchivedEmployeesList
} from "../../actions/Employee/employees";
import { getEmployeeList } from "../../actions/SprayDiary/employee";
import { getTaskNames } from "../../actions/SprayDiary/common";
import { fetchAreasList, fetchAreasLog } from "../../actions/Areas/areas";
import { getFarmsSummary } from "../../actions/Farms/farms";
import { fetchSettings } from "../../actions/Settings";
import { fetchEmployeeGroupsList } from "../../actions/EmployeeGroups/employeeGroups";
import { fetchContractorsList } from "../../actions/Contractors/contractors";
import { getPendingOperationsList } from "../../actions/PendingMovements";
import { onlineSelector } from "../../selectors/common";
import { history } from "../../store";
import Splash from "../../components/Splash";
import { subscribe } from "../../notifications/notifications";
import { Mixpanel } from "../../tools/mixpanel/Mixpanel";
import RoutesPageHolder from "./RoutesPageHolder";
import OfflineMessages from "../../components/OfflineMessages/OfflineMessages";
import InfoMessages from "../../components/InfoMessages/InfoMessages";
import ErrorsListener from "../../components/ErrorsListener/ErrorsListener";
import { fetchTaskTypes } from "../../actions/GeneralTaskCreator/taskCreator";
import ImageViewer from "../../components/ImageViewer";
import { fetchEmployeesTypes } from "actions/Employee/employees";
import { getPendingOperations } from "actions/PendingMovements";
import LogoutPage from "./Logout";

let profilePictureTimeout = null;

class Routes extends Component {
  state = {
    routes: [],
    showOfflineMessage: !this.props.online,
    showApiFailMessage: false,
    showApiSuccessMessage: false
  };

  async componentDidMount() {
    const {
      online,
      user,
      oidcUser,
      actions,
      history: {
        location: { hash }
      }
    } = this.props;
    let userData = await actions.signInSilent();

    const checker = userData?.profile?.resource_accessy[
      "backend-api"
    ]?.roles.includes("clients:add");

    if (this.state.requestStatus === undefined && hash == "" && !checker) {
      const status =
        !this.state.requestStatus || !status
          ? await actions.checkCurrentUser()
          : null;
      this.setState({ requestStatus: status });
    }
    this.state.requestStatus === 401 && history.push("/account/logoff");
    if (hash === "#login" || (!user && !oidcUser)) {
      this.doSignIn();
    } else if (user) {
      if (online && !oidcUser) {
        if (this.state.requestStatus === undefined && hash !== "#login") {
          (this.state.requestStatus < 400 || checker) &&
            this.doSignInSilent(!!user, true);
        }
      } else if (online) {
        if (this.state.requestStatus < 400 || checker) {
          this.getRefreshToken();
          subscribe();
          this.getProfilePicture();
          Mixpanel.identify();
          Mixpanel.updateUserProperties();
        }
      }
      if (
        (this.state.requestStatus &&
          this.state.requestStatus < 400 &&
          hash !== "#login") ||
        checker
      ) {
        this.prefetchLibData();
        this.handleSameHeight();
        window.addEventListener("resize", this.handleSameHeight);
        this.fetchChangeLogInterval = setInterval(() => {
          actions.fetchChemicalsLog();
          actions.fetchEmployeesLog();
          actions.fetchAreasLog();
        }, 1000 * 60);
      }
    }
    this.checkSameUser();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { online, oidcUser, user, isFetching } = this.props;
    if (!user && isFetching) {
      return;
    }
    if (
      ((online && !prevProps.online) || (!oidcUser && online)) &&
      !isFetching
    ) {
      this.doSignInSilent(!!user);
    }

    this.handleSameHeight();
    //    propsChecker(this.props, prevProps);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleSameHeight);
    clearInterval(this.fetchChangeLogInterval);
  }

  handleSameHeight = () => {
    setSameHeight();
  };

  purgeOfflineData = () => {
    return new Promise((resolve, reject) => {
      // Purge disk cached reducer states
      const persistor = persist(store, {}, err => {
        if (err) reject(err);
        resolve();
      });
      persistor.purge();
    });
  };

  checkSameUser = async () => {
    const { user, actions } = this.props;
    const wasUser = localStorage.getItem("wasUser");
    if (wasUser && user && user.profile && user.profile.user_id !== wasUser) {
      localStorage.setItem("wasUser", user.profile.user_id);
      await this.purgeOfflineData();
      await actions.purgeAllData();
      setTimeout(() => {
        document.location.reload();
      }, 100);
    } else if (user && user.profile) {
      localStorage.setItem("wasUser", user.profile.user_id);
      actions.setUserChecked();
    } else {
      actions.setUserChecked();
    }

    return true;
  };

  doSignInSilent = (gotUser, fromLoad) => {
    const {
      actions,
      history: { location }
    } = this.props;

    actions.signInSilent().then(res => {
      const { online, user } = this.props;
      const checker = user?.profile?.resource_accessy[
        "backend-api"
      ]?.roles.includes("clients:add");
      if ((!res || res.error) && online) {
        if (gotUser) {
          history.replace(`${location.pathname}#login`);
          this.doSignOut();
        } else {
          this.doSignIn();
        }
      } else if (online) {
        const { user } = this.props;
        if (fromLoad || !user.refresh_token) {
          if (this.state.requestStatus < 400 || checker) {
            this.getRefreshToken();
          }
          this.getProfilePicture();
        }
        this.checkSameUser();
      }
    });
  };

  doSignIn = () => {
    const { actions, history } = this.props;
    localStorage.setItem(
      "backUrl",
      history.location.pathname.indexOf("/account/logoff") >= 0
        ? "/"
        : history.location.pathname
    );
    actions.signIn(history.location.pathname);
  };

  doSignOut = () => {
    const { actions } = this.props;
    actions.signOut();
  };

  getRefreshToken = () => {
    const { actions, user } = this.props;
    const checker = user?.profile?.resource_accessy[
      "backend-api"
    ]?.roles.includes("clients:add");
    !checker &&
      actions.getRefreshToken().then(res => {
        if (!res || (res && res.error)) {
          this.doSignOut();
        }
      });
  };

  getProfilePicture = () => {
    const { online, user, actions } = this.props;
    if (user && user.profile && online) {
      actions.getProfilePicture();
    }
    clearTimeout(profilePictureTimeout);
    profilePictureTimeout = setTimeout(this.getProfilePicture, 240000);
  };
  prefetchLibData = async () => {
    const { actions } = this.props;
    actions.getMachineryList();
    actions.fetchChemicalsList();
    actions.fetchEmployeesList();
    actions.fetchArchivedEmployeesList();
    // actions.getTaskNames();
    actions.getEmployeeList();
    await actions.fetchAreasList();
    actions.getFarmsSummary();
    actions.fetchSettings();
    actions.fetchEmployeeGroupsList();
    actions.fetchContractorsList();
    actions.getPendingOperationsList();
    actions.fetchTaskTypes();
    actions.fetchEmployeesTypes();
    actions.getPendingOperations({});
  };
  changeRequestStatus = () => {
    this.setState({ requestStatus: undefined });
  };
  render() {
    const {
      routesConfig,
      history: {
        location: { hash }
      },
      userChecked,
      online,
      user,
      location
    } = this.props;
    const checker = user?.profile?.resource_accessy[
      "backend-api"
    ]?.roles.includes("clients:add");
    return (
      <>
        <Switch>
          {!online &&
          (!user ||
            (user &&
              (!user.refresh_token || !user.access_token) &&
              location !== "/account/logoff")) ? (
            <Splash>Please go Online and Reload page first.</Splash>
          ) : (
            hash !== "#login" &&
            userChecked &&
            routesConfig
              .filter(route => route.component)
              .map(route => {
                return (
                  <Route
                    key={`route_${route.path}`}
                    exact={route.exact}
                    path={route.path}
                    render={props =>
                      this.state.requestStatus === 404 && !checker ? (
                        <LogoutPage
                          changeRequestStatus={this.changeRequestStatus}
                        />
                      ) : (
                        <RoutesPageHolder
                          routesPages={routesPages}
                          route={route}
                          {...props}
                        />
                      )
                    }
                  />
                );
              })
          )}
        </Switch>
        <ImageViewer />
        <OfflineMessages online={online} />
        <InfoMessages />
        <ErrorsListener />
      </>
    );
  }
}

Routes.propTypes = {
  user: PropTypes.object,
  oidcUser: PropTypes.object,
  route: PropTypes.object,
  children: PropTypes.array,
  actions: PropTypes.object,
  routesConfig: PropTypes.array,
  history: PropTypes.object,
  canFetch: PropTypes.bool,
  online: PropTypes.bool,
  userChecked: PropTypes.bool,
  list: PropTypes.object,
  location: PropTypes.object,
  isFetching: PropTypes.bool
};

function mapStateToProps(state) {
  const {
    common: { userChecked },
    router: { location },
    user: { isFetching }
  } = state;
  return {
    user: userSelector(state),
    oidcUser: oidcSelector(state),
    routesConfig: routesConfig(state),
    online: onlineSelector(state),
    userChecked,
    location,
    isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        checkCurrentUser,
        signInSilent,
        signIn,
        getMachineryList,
        fetchChemicalsList,
        fetchChemicalsLog,
        fetchEmployeesLog,
        fetchEmployeesList,
        fetchArchivedEmployeesList,
        getTaskNames,
        getEmployeeList,
        fetchAreasList,
        fetchAreasLog,
        getFarmsSummary,
        fetchSettings,
        fetchEmployeeGroupsList,
        fetchContractorsList,
        getPendingOperationsList,
        purgeAllData,
        setUserChecked,
        signOut,
        getRefreshToken,
        getProfilePicture,
        fetchTaskTypes,
        fetchEmployeesTypes,
        getPendingOperations
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);

import { actionType } from "../../constants/GeneralTasks";

const initialState = {
  data: {
    content: [],
    number: 0,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 10
  },
  list: {
    content: [],
    isFetching: false
  },
  taskNames: {
    content: [],
    isFetching: false
  },
  taskIDs: {
    content: [],
    isFetching: false
  },
  tasksType: [],
  subTasks: [],
  location: {},
  isFetching: false,
  isWorkinTimeFetching: false,
  isDeleting: false,
  isCreating: false,
  isArchiving: false,
  isUpdating: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.FETCH_GENERAL_TASKS_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case actionType.FETCH_GENERAL_TASKS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        error: null
      };
    case actionType.SET_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    case actionType.FETCH_GENERAL_TASKS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case actionType.FETCH_GENERAL_TASK_LIST_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case actionType.FETCH_GENERAL_TASK_LIST:
      return {
        ...state,
        list: action.payload,
        isFetching: false,
        error: null
      };
    case actionType.FETCH_GENERAL_TASK_LIST_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case actionType.FETCH_GENERAL_TASK_TYPE_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case actionType.FETCH_GENERAL_TASK_TYPE:
      return {
        ...state,
        tasksType: action.payload,
        isFetching: false,
        error: null
      };
    case actionType.FETCH_GENERAL_TASK_TYPE_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case actionType.FETCH_GENERAL_TASKS_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case actionType.FETCH_SUBTASK_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.FETCH_SUBTASK_LIST:
      return {
        ...state,
        subTasks: [...action.payload],
        isFetching: false
      };
    case actionType.FETCH_SUBTASK_LIST_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case actionType.FETCH_PAYROLL_REPORT_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case actionType.FETCH_PAYROLL_REPORT:
      return {
        ...state,
        data: {
          ...state.data,
          content: action.payload
        },
        isFetching: false,
        error: null
      };
    case actionType.FETCH_PAYROLL_REPORT_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case actionType.FETCH_GENERAL_TASKS_BASIC_INFORMATION:
      return {
        ...state,
        basic: {
          ...state.basic,
          ...action.payload,
          isFetching: true
        },
        error: null
      };
    case actionType.FETCH_GENERAL_TASKS_BASIC_INFORMATION_START:
      return {
        ...state,
        basic: {
          ...state.basic,
          data: action.payload,
          isFetching: false
        },
        error: null
      };
    case actionType.FETCH_GENERAL_TASKS_BASIC_INFORMATION_FAIL:
      return {
        ...state,
        basic: {
          ...state.basic,
          isFetching: false
        },
        error: action.payload
      };
    case actionType.FETCH_GENERAL_TASK_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.GET_GENERAL_TASK_WORKING_TIME_START:
      return {
        ...state,
        isWorkinTimeFetching: true
      };
    case actionType.GENERAL_TASK_WORKING_TIME_DELETE_START:
      return {
        ...state,
        isWorkinTimeFetching: true
      };

    case actionType.FETCH_GENERAL_TASK:
      return {
        ...state,
        data: {
          ...state.data,
          content: [action.payload]
        },
        isFetching: false
      };
    case actionType.GET_GENERAL_TASK_WORKING_TIME:
      const taskId = Object.keys(action.payload)[0];
      const data = Object.values(action.payload)[0];
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content?.map(item =>
            item.id == taskId
              ? {
                  ...item,
                  workingTimes: data
                }
              : item
          )
        },
        isWorkinTimeFetching: false
      };
    case actionType.FETCH_GENERAL_TASK_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case actionType.FETCH_GENERAL_TASK_NAMES_START:
      return {
        ...state,
        taskNames: {
          ...state.taskNames,
          isFetching: true
        }
      };
    case actionType.FETCH_GENERAL_TASK_NAMES:
      return {
        ...state,
        taskNames: {
          ...state.taskNames,
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.FETCH_GENERAL_TASK_NAMES_FAIL:
      return {
        ...state,
        taskNames: {
          ...state.taskNames,
          ...action.payload,
          isFetching: false
        }
      };

    case actionType.FETCH_GENERAL_TASK_IDS_START:
      return {
        ...state,
        taskIDs: {
          ...state.taskIDs,
          isFetching: true
        }
      };
    case actionType.FETCH_GENERAL_TASK_IDS:
      return {
        ...state,
        taskIDs: {
          ...state.taskIDs,
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.FETCH_GENERAL_TASK_IDS_FAIL:
      return {
        ...state,
        taskIDs: {
          ...state.taskIDs,
          isFetching: false
        },
        error: action.payload
      };

    case actionType.DELETE_GENERAL_TASK_START:
      return {
        ...state,
        isDeleting: true
      };
    case actionType.DELETE_GENERAL_TASK:
      return {
        ...state,
        isDeleting: false,
        data: {
          ...state.data,
          content: state.data.content.filter(a => a.id !== action.payload)
        }
      };
    case actionType.DELETE_GENERAL_TASK_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.payload
      };

    case actionType.CREATE_GENERAL_TASK_START:
      return {
        ...state,
        isCreating: true
      };
    case actionType.CREATE_GENERAL_TASK:
      return {
        ...state,
        data: initialState.data,
        isCreating: false
      };
    case actionType.CREATE_GENERAL_TASK_FAIL:
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };

    case actionType.UPDATE_GENERAL_TASK:
      return {
        ...state,
        data: initialState.data,
        isUpdating: true
      };
    case actionType.UPDATE_GENERAL_TASK_START:
      return {
        ...state,
        isUpdating: false
      };
    case actionType.UPDATE_GENERAL_TASK_FAIL:
      return {
        ...state,
        isUpdating: false,
        error: action.payload
      };
    case actionType.UPDATE_GENERAL_TASK_STATUS:
      return {
        ...state,
        isUpdating: false,
        data: {
          ...state.data,
          content: state.data.content.map(task => {
            if (task.id === action.taskId) {
              return {
                ...task,
                status: action.status
              };
            }

            return task;
          })
        }
      };
    case actionType.GENERAL_TASK_WORKING_TIME_DELETE:
      const updatedTask = state.data.content.map(task => {
        return task.id === action.payload.taskId
          ? {
              ...task,
              workingTimes: task.workingTimes.filter(
                wt => wt.id !== action.payload.id
              )
            }
          : task;
      });
      return {
        ...state,
        isWorkinTimeFetching: false,
        data: {
          ...state.data,
          content: updatedTask
        }
      };

    case actionType.ADD_WORKING_TIME_TO_GENERAL_TASK:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(generalTask => {
            if (generalTask.id === action.payload.workingTime.generalTaskId) {
              return {
                ...generalTask,
                workingTimes: [
                  ...generalTask.workingTimes,
                  action.payload.workingTime
                ],
                _workingTimesCount: generalTask._workingTimesCount + 1
              };
            }
            return generalTask;
          })
        }
      };

    case actionType.EDIT_WORKING_TIME_OF_GENERAL_TASK:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(generalTask => {
            if (generalTask.id === action.payload.generalTaskID) {
              generalTask.workingTimes = generalTask.workingTimes.map(wt => {
                if (wt.id === action.payload.workingTimeID) {
                  return action.payload.workingTime;
                }
                return wt;
              });
            }

            return generalTask;
          })
        }
      };

    case actionType.GENERAL_TASK_WORKING_TIME_DELETE_WITH_TASK_START:
      return {
        ...state,
        isDeleting: true
      };
    case actionType.GENERAL_TASK_WORKING_TIME_DELETE_WITH_TASK_DELETE:
      return {
        ...state,
        isDeleting: false,
        data: {
          ...state.data,
          content: state.data.content.map(task => {
            if (task.id === action.payload.taskID)
              return {
                ...task,
                workingTimes: task.workingTimes.filter(
                  wt => wt.id !== action.payload.id
                )
              };

            return task;
          })
        }
      };
    case actionType.GENERAL_TASK_WORKING_TIME_DELETE_WITH_TASK_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.payload
      };

    case actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_START:
      return {
        ...state,
        isArchiving: true
      };
    case actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_ARCHIVE:
      return {
        ...state,
        isArchiving: false,
        data: {
          ...state.data,
          content: state.data.content.map(task => {
            if (task.id === action.payload.taskID)
              return {
                ...task,
                workingTimes: task.workingTimes.filter(
                  wt => wt.id !== action.payload.id
                )
              };

            return task;
          })
        }
      };
    case actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_FAIL:
      return {
        ...state,
        isArchiving: false,
        error: action.payload
      };

    default:
      return state;
  }
};

import React, { useState } from "react";
import { Button, Table, TableCell, TableRow } from "semantic-ui-react";
import TotalCostSubRow from "./TotalCostSubRow";
import { sum, round } from "lodash";
import Numeric from "../../../../components/Numeric";
import style from "../TotalCostPerFarm.module.css";
import TotalCostChemicalRow from "routes/Reports/TotalCostPerArea/components/TotalCostChemicalRow";
const TotalCostRow = ({ area, disableSubRow }) => {
  const generalInfo = {
    totalCost:
      area.sprayTaskLabourCost +
      area.sprayTaskMachineryCost +
      area.chemicalCost +
      sum(
        area.generalTaskCosts.map(task =>
          task.machineryCost ? task.machineryCost : 0
        )
      ) +
      sum(
        area.generalTaskCosts.map(task =>
          task.labourCost ? task.labourCost : 0
        )
      ) +
      sum(
        area.generalTaskCosts.map(task =>
          task.altLabourCost ? task.altLabourCost : 0
        )
      ) +
      area.otherExpensesCost +
      area.overheadsCost,

    totalCostPerHa:
      area.areaSize && area.areaSize != 0
        ? (area.sprayTaskLabourCost +
            area.sprayTaskMachineryCost +
            area.chemicalCost +
            sum(
              area.generalTaskCosts.map(task =>
                task.machineryCost ? task.machineryCost : 0
              )
            ) +
            sum(
              area.generalTaskCosts.map(task =>
                task.labourCost ? task.labourCost : 0
              )
            ) +
            sum(
              area.generalTaskCosts.map(task =>
                task.altLabourCost ? task.altLabourCost : 0
              )
            ) +
            area.otherExpensesCost +
            area.overheadsCost) /
          area.areaSize
        : "No information"
  };
  const chemicalCost = {
    totalCosts: round(area.chemicalCost, 2),
    costPerHa: area.areaSize
      ? round(area.chemicalCost / area.areaSize, 2)
      : "No information",
    costPerHarvestUnit: area.binCount
      ? round(area.chemicalCost / area.binCount, 2)
      : "No information"
  };
  const operatingCost = {
    totalCosts: round(area.otherExpensesCost + area.overheadsCost, 2),
    costPerHa: area.areaSize
      ? round((area.otherExpensesCost + area.overheadsCost) / area.areaSize, 2)
      : "No information",
    costPerHarvestUnit: area.binCount
      ? round((area.otherExpensesCost + area.overheadsCost) / area.binCount, 2)
      : "No information"
  };
  const overheadsCost = {
    totalCosts: round(area.overheadsCost, 2),
    costPerHa: area.areaSize
      ? round(area.overheadsCost / area.areaSize, 2)
      : "No information",
    costPerHarvestUnit: area.binCount
      ? round(area.overheadsCost / area.binCount, 2)
      : "No information"
  };
  const overheadsCostByType = area.operatingCosts
    .filter(item => item.type === "OVERHEADS")
    .map(item => {
      return {
        subType: item.subtype,
        totalCosts: round(item.cost, 2),
        costPerHa: area.areaSize
          ? round(item.cost / area.areaSize, 2)
          : "No information",
        costPerHarvestUnit: area.binCount
          ? round(item.cost / area.binCount, 2)
          : "No information"
      };
    });

  const otherExpensesCost = {
    totalCosts: round(area.otherExpensesCost, 2),
    costPerHa: area.areaSize
      ? round(area.otherExpensesCost / area.areaSize, 2)
      : "No information",
    costPerHarvestUnit: area.binCount
      ? round(area.otherExpensesCost / area.binCount, 2)
      : "No information"
  };
  const othersCostByType = area.operatingCosts
    .filter(item => item.type === "OTHER_EXPENSES")
    .map(item => {
      return {
        subType: item.subtype,
        totalCosts: round(item.cost, 2),
        costPerHa: area.areaSize
          ? round(item.cost / area.areaSize, 2)
          : "No information",
        costPerHarvestUnit: area.binCount
          ? round(item.cost / area.binCount, 2)
          : "No information"
      };
    });

  const labourCosts = {
    totalCosts: round(
      sum(
        area.generalTaskCosts.map(task =>
          task.labourCost ? task.labourCost : 0
        )
      ) +
        sum(
          area.generalTaskCosts.map(task =>
            task.altLabourCost ? task.altLabourCost : 0
          )
        ) +
        (area.sprayTaskLabourCost ? area.sprayTaskLabourCost : 0),
      2
    ),

    costPerHa: area.areaSize
      ? round(
          (sum(
            area.generalTaskCosts.map(task =>
              task.labourCost ? task.labourCost : 0
            )
          ) +
            sum(
              area.generalTaskCosts.map(task =>
                task.altLabourCost ? task.altLabourCost : 0
              )
            ) +
            (area.sprayTaskLabourCost ? area.sprayTaskLabourCost : 0)) /
            area.areaSize,
          2
        )
      : "No information",

    costPerHarvestUnit: area.binCount
      ? round(
          (sum(
            area.generalTaskCosts.map(task =>
              task.labourCost ? task.labourCost : 0
            )
          ) +
            sum(
              area.generalTaskCosts.map(task =>
                task.altLabourCost ? task.altLabourCost : 0
              )
            ) +
            (area.sprayTaskLabourCost ? area.sprayTaskLabourCost : 0)) /
            area.binCount,
          2
        )
      : "No information",

    tasks: area.generalTaskCosts,
    sprayTaskLabourCost: area.sprayTaskLabourCost,
    costSwitcher: false,
    type: 1,
    areaSize: area.areaSize,
    binCount: area.binCount
  };
  const machineryCosts = {
    totalCosts: round(
      sum(
        area.generalTaskCosts.map(task =>
          task.machineryCost ? task.machineryCost : 0
        )
      ) + (area.sprayTaskMachineryCost ? area.sprayTaskMachineryCost : 0),
      2
    ),

    costPerHa: area.areaSize
      ? round(
          (sum(
            area.generalTaskCosts.map(task =>
              task.machineryCost ? task.machineryCost : 0
            )
          ) +
            (area.sprayTaskMachineryCost ? area.sprayTaskMachineryCost : 0)) /
            area.areaSize,
          2
        )
      : "No information",

    costPerHarvestUnit: area.binCount
      ? round(
          (sum(
            area.generalTaskCosts.map(task =>
              task.machineryCost ? task.machineryCost : 0
            )
          ) +
            (area.sprayTaskMachineryCost ? area.sprayTaskMachineryCost : 0)) /
            area.binCount,
          2
        )
      : "No information",
    tasks: area.generalTaskCosts,
    sprayTaskMachineryCost: area.sprayTaskMachineryCost,
    costSwitcher: true,
    type: 2,
    areaSize: area.areaSize,
    binCount: area.binCount
  };

  const gropuedArea = area.areas
    ? area.areas
        .map(item => ({
          ...item,
          chemicalCostsDetailed: item.chemicalCostsDetailed.map(a => ({
            ...a,
            totalCost: a.chemicalCostPerHa * item.areaSize
          }))
        }))
        .map(i => i.chemicalCostsDetailed)
        .flat()
    : [];

  const groupedObject = {};
  gropuedArea &&
    gropuedArea.forEach(item => {
      if (groupedObject[item.chemicalType]) {
        groupedObject[item.chemicalType].totalCost += item.totalCost;
      } else {
        groupedObject[item.chemicalType] = {
          chemicalType: item.chemicalType,
          totalCost: item.totalCost
        };
      }
    });
  const chemicalCostNew = {
    totalCosts: area?.chemicalCost,
    costPerHa: area?.areaSize
      ? round(area?.chemicalCost / area?.areaSize, 2)
      : "No information",
    costPerHarvestUnit: area?.binCount
      ? round(area?.chemicalCost / area?.binCount, 2)
      : "No information",
    tasks: Object.values(groupedObject).map(item => ({
      chemicalType: item.chemicalType,
      chemicalCostPerHa: item.totalCost
        ? round(item.totalCost / area?.areaSize, 2)
        : "No information",
      chemicalCostPerHarvestUnit: item.totalCost
        ? round(item.totalCost / area?.binCount, 2)
        : "No information",
      chemicalTotalCosts: item.totalCost || "No information"
    })),
    areaSize: area?.areaSize,
    binCount: area?.binCount
  };
  const [isExpanded, setExpanded] = useState(false);
  const [isExpandedOperating, setExpandedOperating] = useState(false);
  const [isExpandedOverheads, setExpandedOverheads] = useState(false);
  const [isExpandedOther, setExpandedOther] = useState(false);
  console.log("chemicalCost new data: ", chemicalCostNew);
  console.log("chemicalCost old data: ", chemicalCost);

  return (
    <>
      <TableRow style={isExpanded ? { backgroundColor: "#dff3ea" } : {}}>
        {!disableSubRow && (
          <TableCell width={1}>
            <Button
              active={isExpanded}
              onClick={() => setExpanded(!isExpanded)}
              compact
              icon="dropdown"
            />
          </TableCell>
        )}
        <Table.Cell style={{ fontWeight: "bold" }} width={2}>
          {area.areaName}
        </Table.Cell>
        <Table.Cell style={{ fontWeight: "bold" }} width={2}>
          {area.areaSize ? `${round(area.areaSize, 2)} Ha` : "No information"}
        </Table.Cell>
        <Table.Cell style={{ fontWeight: "bold" }} width={2}>
          <Numeric
            value={
              area.binCount
                ? round(generalInfo.totalCost / area.binCount, 2)
                : "No information"
            }
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell style={{ fontWeight: "bold" }} width={2}>
          <Numeric
            value={
              isNaN(generalInfo.totalCostPerHa)
                ? "No information"
                : round(generalInfo.totalCostPerHa, 2)
            }
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
          /Ha
        </Table.Cell>
        <Table.Cell style={{ fontWeight: "bold" }} width={2}>
          <Numeric
            value={round(generalInfo.totalCost, 2)}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
      </TableRow>
      {isExpanded && (
        <TableCell
          style={isExpanded ? { backgroundColor: "#dff3ea" } : {}}
          colSpan={16}
        >
          <Table colSpan={16}>
            <Table.Body>
              <TotalCostChemicalRow
                name={"Chemical and Fertiliser Costs ($)"}
                data={chemicalCostNew}
              />
              <TotalCostSubRow costsType={"labour"} data={labourCosts} />
              <TotalCostSubRow costsType={"machinery"} data={machineryCosts} />
              <Table.Row style={{ fontWeight: "bold" }}>
                <TableCell width={1}>
                  <Button
                    active={isExpandedOperating}
                    onClick={() => setExpandedOperating(!isExpandedOperating)}
                    compact
                    icon="dropdown"
                  />
                </TableCell>
                <Table.Cell width={3}>Operating Costs ($)</Table.Cell>
                <Table.Cell width={1}></Table.Cell>
                <Table.Cell width={2}>
                  <Numeric
                    value={operatingCost.costPerHarvestUnit}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  <Numeric
                    value={operatingCost.costPerHa}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
                <Table.Cell width={2}>
                  <Numeric
                    value={operatingCost.totalCosts}
                    fractionDigits={2}
                    commaSeparatorOnThousands
                    units="$ "
                    unitsPosition="left"
                  />
                </Table.Cell>
              </Table.Row>
              {isExpandedOperating && (
                <TableCell colSpan={16}>
                  <Table colSpan={16} unstackable>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={1}>
                          <Button
                            active={isExpandedOverheads}
                            onClick={() =>
                              setExpandedOverheads(!isExpandedOverheads)
                            }
                            compact
                            icon="dropdown"
                          />
                        </Table.Cell>
                        <TableCell width={3}>{`Overheads`}</TableCell>
                        <Table.Cell width={1}></Table.Cell>
                        <Table.Cell width={2}>
                          <Numeric
                            value={overheadsCost.costPerHarvestUnit}
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <Numeric
                            value={overheadsCost.costPerHa}
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <Numeric
                            value={overheadsCost.totalCosts}
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                      </Table.Row>
                      {isExpandedOverheads &&
                        overheadsCostByType.map(item => (
                          <Table.Row>
                            <TableCell
                              className={style.expandedTabelRow}
                              colSpan={16}
                            >
                              <Table colSpan={16} unstackable>
                                <Table.Body>
                                  <Table.Row>
                                    <Table.Cell width={1}></Table.Cell>
                                    <TableCell width={3}>
                                      {item.subType}
                                    </TableCell>
                                    <Table.Cell width={1}></Table.Cell>
                                    <Table.Cell
                                      style={{ paddingLeft: 2 }}
                                      width={2}
                                    >
                                      <Numeric
                                        value={item.costPerHarvestUnit}
                                        fractionDigits={2}
                                        commaSeparatorOnThousands
                                        units="$ "
                                        unitsPosition="left"
                                      />
                                    </Table.Cell>
                                    <Table.Cell width={2}>
                                      <Numeric
                                        value={item.costPerHa}
                                        fractionDigits={2}
                                        commaSeparatorOnThousands
                                        units="$ "
                                        unitsPosition="left"
                                      />
                                    </Table.Cell>
                                    <Table.Cell
                                      style={{ paddingLeft: 15 }}
                                      width={2}
                                    >
                                      <Numeric
                                        value={item.totalCosts}
                                        fractionDigits={2}
                                        commaSeparatorOnThousands
                                        units="$ "
                                        unitsPosition="left"
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                            </TableCell>
                          </Table.Row>
                        ))}
                      <Table.Row>
                        <Table.Cell width={1}>
                          <Button
                            active={isExpandedOther}
                            onClick={() => setExpandedOther(!isExpandedOther)}
                            compact
                            icon="dropdown"
                          />
                        </Table.Cell>
                        <TableCell width={3}>{`One-off purchases`}</TableCell>
                        <Table.Cell width={1}></Table.Cell>
                        <Table.Cell width={2}>
                          <Numeric
                            value={otherExpensesCost.costPerHarvestUnit}
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <Numeric
                            value={otherExpensesCost.costPerHa}
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <Numeric
                            value={otherExpensesCost.totalCosts}
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                      </Table.Row>
                      {isExpandedOther &&
                        othersCostByType.map(item => (
                          <Table.Row>
                            <TableCell
                              className={style.expandedTabelRow}
                              colSpan={16}
                            >
                              <Table colSpan={16} unstackable>
                                <Table.Body>
                                  <Table.Row>
                                    <Table.Cell width={1}></Table.Cell>
                                    <TableCell width={3}>
                                      {item.subType}
                                    </TableCell>
                                    <Table.Cell width={1}></Table.Cell>
                                    <Table.Cell
                                      style={{ paddingLeft: 2 }}
                                      width={2}
                                    >
                                      <Numeric
                                        value={item.costPerHarvestUnit}
                                        fractionDigits={2}
                                        commaSeparatorOnThousands
                                        units="$ "
                                        unitsPosition="left"
                                      />
                                    </Table.Cell>
                                    <Table.Cell width={2}>
                                      <Numeric
                                        value={item.costPerHa}
                                        fractionDigits={2}
                                        commaSeparatorOnThousands
                                        units="$ "
                                        unitsPosition="left"
                                      />
                                    </Table.Cell>
                                    <Table.Cell
                                      style={{ paddingLeft: 15 }}
                                      width={2}
                                    >
                                      <Numeric
                                        value={item.totalCosts}
                                        fractionDigits={2}
                                        commaSeparatorOnThousands
                                        units="$ "
                                        unitsPosition="left"
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                            </TableCell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </TableCell>
              )}
            </Table.Body>
          </Table>
        </TableCell>
      )}
    </>
  );
};

export default TotalCostRow;

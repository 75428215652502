import React from "react";
import PT from "prop-types";
import Slider from "rc-slider";
import styles from "./CustomSlider.module.css";
import "rc-slider/assets/index.css";

const SliderComponent = Slider.createSliderWithTooltip(Slider);
const CustomSlider = ({
  values,
  item,
  setFieldValue,
  onChangeValue = null,
  sliderValue
}) => {
  return (
    <>
      <SliderComponent
        className={styles.customSlider}
        max={5}
        min={0}
        onChange={e => {
          return onChangeValue
            ? onChangeValue("rate", e)
            : setFieldValue(
                "employeeResults",
                values.employeeResults.map(i =>
                  i.id === item?.id ? { ...i, rate: e } : i
                )
              );
        }}
        step={1}
        tipFormatter={value => value}
        value={
          onChangeValue
            ? values.rate
            : values.employeeResults.find(i => i.id === item?.id)?.rate
        }
        dots
      />
      <div className={styles.pointNumberWrapper}>
        <span className={styles.pointNumber}>0</span>
        <span className={styles.pointNumber}>1</span>
        <span className={styles.pointNumber}>2</span>
        <span className={styles.pointNumber}>3</span>
        <span className={styles.pointNumber}>4</span>
        <span className={styles.pointNumber}>5</span>
      </div>
    </>
  );
};

CustomSlider.propTypes = {
  onClick: PT.func,
  setSliderValue: PT.func,
  sliderValue: PT.number
};
CustomSlider.defaultProps = {
  onClick: () => {},
  setSliderValue: () => {},
  sliderValue: 0
};
export default CustomSlider;

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Confirm, Header, Icon, Segment, Table } from "semantic-ui-react";
import styles from "./GeneralTasksTable.module.css";
import moment from "moment";
import { useDispatch, connect } from "react-redux";
import {
  deleteGeneralTask,
  fetchGeneralTasksWorkingTimes,
  fetchSubTaskList
} from "../../../../actions/GeneralTasks/generalTasks";
import ToolTip from "../../../../components/ToolTip";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import WorkingTimesTable from "./WorkingTimesTable";
import { GeneralTask } from "../../../../models/general_task.model";
import ShowMore from "./ShowMore";
import { getHashParameters, removeHashParameters } from "utils/hashToObject";
import { getFarmsSummary } from "actions/Farms/farms";
import { history } from "../../../../store";

const formatDate = date => (date ? moment(date).format("DD/MM/YYYY") : "-");

function GeneralTasksTable({
  isWorkinTimeFetching,
  location,
  task,
  setTask,
  summary,
  dateFrom,
  dateTo
}) {
  task = new GeneralTask(task);

  const dispatch = useDispatch();
  const [openTable, toggleOpenTable] = useState(false);
  const [deleteTaskID, setDeleteTaskID] = useState(null);

  const isSmallScreen = useMediaQuery({ maxWidth: 730 });
  const params = getHashParameters(location);

  const onDeleteConfirm = () =>
    dispatch(deleteGeneralTask(deleteTaskID)).then(() => setDeleteTaskID(null));

  const isWorkingTimesExisting = task.workingTimes.length > 0;
  const expandedChecker = async () => {
    const dispatchAndGetFarmsSummary = dispatch => {
      return new Promise(resolve => {
        dispatch(getFarmsSummary());
        resolve(); // Resolving immediately after dispatching
      });
    };

    if ((!summary || !summary?.length) && !openTable) {
      await dispatchAndGetFarmsSummary(dispatch); // Only dispatch if summary.length is falsy
    }
    !openTable &&
      dispatch(
        fetchGeneralTasksWorkingTimes({
          dateFrom,
          dateTo,
          id: task.id,
          filters: { taskId: task.id }
        })
      );
    task.id == params.id && params.workinTimeTable
      ? removeHashParameters(location, ["workinTimeTable"]) &&
        toggleOpenTable(true)
      : toggleOpenTable(!openTable);
  };

  const clickHendler = async () => {
    dispatch(fetchSubTaskList());
  };
  const handleEditClick = () => {
    !summary || !summary?.length
      ? dispatch(getFarmsSummary()).then(() =>
          history.push(`/tasks/update/${task.type.id}_${task.id}`)
        )
      : history.push(`/tasks/update/${task.type.id}_${task.id}`);
  };
  return (
    <Segment className={styles.tableHolder}>
      <Confirm
        content={"Are you sure you want to delete this task?"}
        open={!!deleteTaskID}
        onConfirm={onDeleteConfirm}
        onCancel={() => setDeleteTaskID(null)}
      />
      <Table
        className={`${styles.tableFarm} ${openTable ? styles.tableOpen : ""}`}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>
              <div
                className={`${styles.toggleIcon} ${
                  openTable ? styles.openTable : ""
                }`}
              >
                <Icon
                  className={
                    openTable ||
                    (task.id == params.id && params.workinTimeTable)
                      ? "tuf-chevron-down"
                      : "tuf-chevron-right"
                  }
                  onClick={() => expandedChecker()}
                />
              </div>
            </Table.HeaderCell>

            <Table.HeaderCell width={4}>
              <div className={styles.generalTaskListHeading}>
                <Header
                  className={styles.taskName}
                  size={"large"}
                  onClick={() => setTask(task.id)}
                >
                  GT-{task.id}{" "}
                  {task.name.length > 20
                    ? `${task.name.substr(0, 20)}...`
                    : task.name}
                </Header>
                {isSmallScreen && (
                  <div
                    onClick={() => handleEditClick()}
                    className={styles.actions}
                  >
                    <Icon className={`tuf-pen ${styles.tasksEditIcon}`} />
                  </div>
                )}
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <b>Areas:</b>
              <br />
              <span>
                <ShowMore
                  items={
                    task.areas.length
                      ? task.areas?.map(({ area }) =>
                          area.type === "PATCH"
                            ? `${area.parent.name} - ${area.name}`
                            : area.name
                        )
                      : ["-"]
                  }
                />
              </span>
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              <b>Type:</b>
              <br />
              <div className={styles.typePlaceholder}>
                <img
                  width={25}
                  height={25}
                  src={task.type.logo.presignedUrl}
                  alt=""
                />
                <span style={{ marginLeft: 10 }}>{task.type.name}</span>
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <b>Status:</b>
              <br />
              <span>{task.status && task.status.replace(/_/gi, " ")}</span>
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <b>Priority:</b>
              <br />
              <span>{task.priority || "-"}</span>
            </Table.HeaderCell>
            <Table.HeaderCell width={4}>
              <b>Assigned employees:</b>
              <br />
              <span>
                {task.assignedTo
                  ?.map(
                    ({ employee }) =>
                      `${employee.firstName} ${employee.lastName}`
                  )
                  .join(", ")}
              </span>
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <b>Dates:</b>
              <br />
              {formatDate(task.startAt)} {formatDate(task.endAt)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={1}>
              {isSmallScreen || (
                <ToolTip content="Edit">
                  <Icon
                    className={`tuf-pen ${styles.tasksEditIcon}`}
                    onClick={() => {
                      return handleEditClick(), clickHendler();
                    }}
                  />
                </ToolTip>
              )}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={1}>
              {isSmallScreen || isWorkingTimesExisting || (
                <ToolTip content="Delete">
                  <Icon
                    className={"tuf-trash-alt"}
                    onClick={() => setDeleteTaskID(task.id)}
                  />
                </ToolTip>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {(openTable || (task.id == params.id && params.workinTimeTable)) && (
          <WorkingTimesTable
            isWorkinTimeFetching={isWorkinTimeFetching}
            task={task}
            workingTimes={task.workingTimes}
          />
        )}
      </Table>
    </Segment>
  );
}

GeneralTasksTable.propTypes = {
  refetch: PropTypes.func.isRequired,
  setTask: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired
};

GeneralTasksTable.defaultProps = {
  setTask: () => {}
};

export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");
  return {
    summary: state.farms.summary
  };
})(GeneralTasksTable);
